var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('common-table',{ref:"ct",attrs:{"headers":_vm.headers,"listData":_vm.listData,"btnAction":true,"loading":_vm.loading,"rightConfig":_vm.rightConfig,"leftConfig":_vm.leftConfig,"listDeploy":_vm.listDeploy,"pageTitle":"三级栏目","btnActionEvent":_vm.btnActionEvent,"pageKey":"menuid","itemScope":['mname','show']},on:{"search":_vm.search,"remove":_vm.remove,"save":_vm.save,"edit":_vm.edit,"changePage":_vm.changePage,"beforeOpenAddDialog":_vm.beforeOpenAddDialog,"otherTableOperate":_vm.otherTableOperate},scopedSlots:_vm._u([{key:"mname",fn:function(ref){
var item = ref.item;
return [_c('div',{style:({
            color: 'var(--v-highlightBlue-base)',
            cursor: 'pointer',
          }),on:{"click":function($event){return _vm.asv(item.mname)}}},[_vm._v(" "+_vm._s(item.mname)+" ")])]}},{key:"show",fn:function(ref){
          var item = ref.item;
return [_c('w-switches',{attrs:{"inset":"","label":"","color":"primary","hide-details":"","dense":""},on:{"change":function($event){return _vm.updateStatus(item.sqlid)}},model:{value:(item.show),callback:function ($$v) {_vm.$set(item, "show", $$v)},expression:"item.show"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }